
import {Component} from 'vue-property-decorator';
import FilterBar from "@/components/ats/FilterBar.vue";
import {ROUTES} from "@/data";
import {mixins} from "vue-class-component";
import SelfInitLayout from "@/mixins/SelfInitLayout.vue";
import TitleManager from "@/mixins/TitleManager.vue";
import { Getter } from 'vuex-class';
import ActionButton from '@/components/ActionButton.vue';
import NewIcon from '@/components/NewIcon.vue';
import { showModal } from '@/helpers/callables';
import WorkerAddVerificationModal from '@/components/modal/WorkerAddVerificationModal.vue';

@Component({
    name: "CVLayout",
    components: {FilterBar, ActionButton, NewIcon, WorkerAddVerificationModal}
})
export default class CVLayout extends mixins(SelfInitLayout, TitleManager) {
    filters: any = {
        search: "",
        jobOfferId: "",
        source: "",
        jobId: "",
        fillingRate: 0
    };
    ROUTES = ROUTES
    showWorkerAddVerificationModal = false;

    @Getter('isSupervisor') isSupervisor!: boolean;

    get jobOfferId() {
        return this.$route.params.jobOfferId;
    }

    get isJobOffer() {
        return this.$route.name === ROUTES.ATS.JOBOFFER.READ.CANDIDATES;
    }

    get isNewCandidate() {
        return this.$route.name === ROUTES.ATS.CV.NEWCANDIDAT;
    }

    addCandidate() {
        this.$router.push({name: ROUTES.ATS.CV.NEWCANDIDAT})
    }

    toggleAddInterimVerificationModal() {
        this.showWorkerAddVerificationModal = !this.showWorkerAddVerificationModal ;
        if(this.showWorkerAddVerificationModal) showModal.call(this, 'WorkerAddVerificationModal');
        else this.$modal.hide('WorkerAddVerificationModal');
    }

    beforeMount() {
        if (this.isJobOffer && this.jobOfferId) {
            this.filters.jobOfferId = this.jobOfferId;
        }
    }
}
